















































































import { defineComponent, ref } from "@vue/composition-api";
import { StaseResidenFM } from "../models/staseForm";
import useFormModel from "@/apps/core/modules/useFormModel";
import router from "@/router";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import { debounce } from "lodash";
import { GenericListAPI } from "@/apps/core/api/list";
import { SaveContext } from "@/apps/core/models/formModel";

export default defineComponent({
  name: "StaseDetail",
  components: {
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    ResidenTable: () => import("@/apps/stase/components/ResidenTable.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    // const staseVM = new StaseVM();
    // const { viewModelRef, instance } = useViewModel(paramsId, staseVM);
    // const stase = instance as Stase;

    const requiredFields = [
      "staseresidenSet",
    ];
    const form = new StaseResidenFM(requiredFields, []);
    const composition = useFormModel(form, paramsId);

    const residenTags = ref([] as Array<Record<string, string>>);

    const getFilteredResidenTags = async (nama: string) => {
      const url = `/anggota/residen/?fields_type=ac&q=${nama}`;
      const api = new GenericListAPI(url);
      const optionArr = await api.fetch();
      residenTags.value = optionArr.reverse();
    };

    const fetchResidenTags = debounce(getFilteredResidenTags, 500);
    const batchCreate = () => composition.formRef.save(SaveContext.Create);
    return {
      // Data
      stase: composition.instance,
      residenTags,

      // Composition
      ...composition,
      toUserDateFormat,

      // Method
      batchCreate,
      fetchResidenTags,
    };
  },
});
